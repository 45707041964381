import React from "react";
import styles from './Switch.module.css'

const ToggleButton = ({
                          isOn = false,
                          onToggle = () => {},
                          disabled = false,
                          colors: {backgroundOn = "#00A469", backgroundOff = "#EDEDED"} = {},
                      }) => {

    function onChange() {
        if (disabled) return
        onToggle(!isOn)
    }

    return (
        <div onClick={onChange}
            style={{opacity: disabled ? 0.5 : 1,cursor:'pointer'}}
        >
            <div className={styles.background} style={{
                backgroundColor: isOn ? backgroundOn : backgroundOff,
                justifyContent: isOn ? "flex-end" : "flex-start",
            }}>
                <div className={styles.switch} />
            </div>
        </div>
    );
};

export default ToggleButton;