export default {
  darkBlue: "#213140",
  mainGreen: "#00A469",
  mainGreenDimmed: "#ABEBC6",
  red: "#FF5252",
  orange: "#1850DF",
  grayBackground: "#EDEDED",
  grayShape: "#C7C7C7",
  grayFont: "#909090",
  white: '#fff'
}

export const categoryColors = [
  '#F3647E', "#00A469",
  '#1850DF'
]

export function idToColor (id, colorsArray) {
  return colorsArray[Number(id) % colorsArray.length]
}
