export const BASE_URL = 'https://api.halalfoods.ru/api'
export const DOMAIN_URL = 'https://api.halalfoods.ru/'

export const MapKitKeys = [
  '95f79f13-83ec-42b6-890b-db1b8b823e92',
  '329a49e2-df63-442e-b4d1-e74b00614e8a',
]

export const GeocoderKeys = [
  'a08d8928-8db6-4c85-bcf5-149d469739ef',
  '7020eded-f424-41c9-a1ee-3b900407ec1d',
]

export function getRandomMapKey () {
  return MapKitKeys[1]

  const index = Math.round(Math.random())*(MapKitKeys.length - 1)
  return MapKitKeys[index]
}

export const addressSearchBounds = '29.327057,60.243699~39.589145,54.931679'

export const SupportPhoneNumber = '+7 495 215-51-44'

export const SupportWANumber = '+7 966 036-34-33'


export const inputFormat = 'YYYY-MM-DD HH:mm:SS'
export const targetFormat = 'HH:mm'
export const dayFormat = 'LL'
