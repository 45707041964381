import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";

import LandingLogo from "./../../assets/images/landingLogo.svg"
import Iphone from "./../../assets/images/iphone.png"
import Iphone2 from "./../../assets/images/iphone2.png"
import Qr from "./../../assets/images/qr.png"
import CloseButton from "./../../assets/images/closeButton.svg" 

import "./Landing.css"

const FirstLine = () =>
{
    return (
        <div className="Landing_firstLine__3Y70W">
            <div className="Landing_wrapper__1dp4W">
                <div className="Landing_holder__13iQw">
                    <img src={LandingLogo} className="Landing_logo__r8sdC"/>
                    <div className="Landing_textHolder__3k0SG">
                        <p>Halal Foods — онлайн магазин <br/>с доставкой от 60 минут.</p><p>Привозим халяльные проверенные продукты, <br/>а также все что нужно для дома!</p><p>Мы работаем 24/7 по всей Москве, Питеру, <br/>Казани и Нижнему Новгороду.</p>
                    </div>
                </div>
                <img src={Iphone} className="Landing_iphone__2K7u0"/>
            </div>
        </div>
    )
}

const SecondLine = () =>
{
    return (
        <div className="Landing_secondLine__3aJB9">
            <div className="Landing_wrapper__1dp4W">
                <img src={Qr} className="Landing_qr__3n-ZQ" />
                <div className="Landing_textHolder__3k0SG">
                    <p>HalalFoods в Apple Store и Google Play</p><p>Скачайте приложение и получите <br/>300 баллов на заказ</p>
                    <a href="https://onelink.to/2s8n74">Скачать</a>
                </div>
            </div>
        </div>
    )
}

const ThirdLine = () =>
{
    return (
        <div className="Landing_thirdLine__F-Ke0">
            <div className="Landing_wrapper__1dp4W">
                <img src={Iphone2} className="Landing_iphone__2K7u0" />
                <div className="Landing_holder__13iQw">
                    <div className="Landing_textHolder__3k0SG">
                        <p>Продукты доставляются в специально <br/>оборудованных машинах с холодильниками, <br/>для сохранения свежести продуктов!</p>
                        <p>Бесплатная доставка от 2000 рублей <br/>по центральной части.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FourthLine = ({openModal}) =>
{
    return (
        <div className="Landing_fourthLine__3sqxT">
            <div className="Landing_wrapper__1dp4W">
                <div className="Landing_textHolder__3k0SG">
                    <p>Вся мясная продукция <br/>имеет <span>сертификаты <br/>Халяль.</span></p>
                    <p>У нас три уровня <br/>проверки качества <br/>продуктов:</p>
                    <div className="Landing_numeric__2Qw4D">
                        <span>1</span>на приёмке
                    </div>
                    <div className="Landing_numeric__2Qw4D">
                        <span>2</span>на сборке
                    </div>
                    <div className="Landing_numeric__2Qw4D">
                        <span>3</span>на доставке
                    </div>
                </div>
                <div className="Landing_rightBlock__2Pv0h">
                    <p>Так же два раза в день наши сотрудники <br/>проверяют срок годности товаров и внешний вид <br/>фруктов и овощей.</p>
                    <p>Мы поддерживаем температуру 2–4°С <br/>в холодильниках и 18°С в морозильных камерах.</p>
                    <p>Вы можете быть уверены, что вам приедут <br/>вкусные и натуральные продукты!</p>
                    <a onClick={() => openModal(true)}>Посмотреть сертификаты</a>
                </div>
            </div>
        </div>
    )
}

const CertificatesShowCase = ({isOpenedModal, openModal}) =>
{
    if (!isOpenedModal) {
        return null
    }

    return (
        <div className="Landing_certWrapper__3FJj_">
            <div className="Landing_certOverlay__10tTX" onClick={()=> openModal(false)} />
            <div className="Landing_certHolder__1Qxff">
                <a 
                    className="TouchableOpacity_element__3ZTwa Landing_closer__1OgWY" 
                    style={{
                        width: '32px',
                        height: '32px',
                        borderRadius: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgb(243, 243, 243)'
                    }}
                    onClick={() => openModal(false)}
                >
                    <img src={CloseButton} style={{width: '18px', height: '18px', objectFit: 'contain'}} />
                </a>
                <img src="/certs/1.jpeg" />
                <img src="/certs/2.jpeg" />
                <img src="/certs/3.jpeg" />
                <img src="/certs/4.jpeg" />
                <img src="/certs/5.jpeg" />
                <img src="/certs/6.jpeg" />
                <img src="/certs/7.jpg" />
                <img src="/certs/8.jpg" />
                <img src="/certs/9.jpg" />
                <img src="/certs/10.jpg" />
                <img src="/certs/11.jpg" />
                <img src="/certs/13.jpg" />
                <img src="/certs/14.jpg" />
                <img src="/certs/15.jpg" />
                <img src="/certs/16.jpg" />
                <img src="/certs/17.jpg" />
                <img src="/certs/18.jpg" />
            </div>
        </div>
    )
}

const Landing = () => {
    const [isOpenedModal, openModal] = useState(false)

    return (
        <>
            <FirstLine />
            <SecondLine />
            <ThirdLine />
            <FourthLine openModal={openModal} />
            <CertificatesShowCase openModal={openModal} isOpenedModal={isOpenedModal} />
        </>
    )
}

export default Landing