import React, {useContext, useEffect, useState} from "react";
import styles from "./DeliveryAndPayment.module.css"
import SText from "./SText";
import Header from "./Header";
import mapPin from "../assets/images/MapPin.svg"
import {useCoolDown, useWindowDimensions} from "../utils/hooks";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import DataContext from "../data/DataContext";
import {getGeoSuggestions, validateLocation} from "../api";
import TouchableOpacity from "./TouchableOpacity";
import cn from "classnames";
import Colors from "../utils/Colors";
import tick from "../assets/images/tick.svg"
import BreadCrumbs from "./BreadCrumbs";
import {Helmet} from "react-helmet";
import {RegionContext} from "../App";

const Interval = ({price = '150 р', day = 'Понедельник'}) => {

    return <div className={styles.intervalWrapper}>
        <SText style={{width: '97px'}} fontSize={14} fontWeight={400} lineHeight={15} color={'#5A5A5A'}>{day}</SText>
        {/*        <SText fontSize={14} fontWeight={price === 'бесплатно' ? 550 : 400} lineHeight={15}
               color={price === 'бесплатно' ? '#00A469' : '#5A5A5A'}>{price}</SText>*/}
        <SText fontSize={14} fontWeight={400} lineHeight={15} color={'#5A5A5A'}>00:00 - 23:59</SText>
    </div>
}

const GeoInput = ({visible, setVisible}) => {

    const [value, setValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [candidate, setCandidate] = useState({valid: false})
    const {userLocation} = useContext(DataContext)
    const {windowDimensions: {width}} = useWindowDimensions()
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)

    async function _getSuggestions(input) {
        if (input === '')
            return setSuggestions([])

        const response = await getGeoSuggestions(input)
        setSuggestions(response)
    }

    const getSuggestions = useCoolDown(_getSuggestions)

    async function handleSuggestionSelect(name, point) {
        setValue(name)
        const response = await validateLocation(point.lat, point.lon) || false
        setCandidate({
            name, point, valid: response.validate || false
        })
    }

    async function onSubmit() {
        if (!candidate.valid) return
        await userLocation.update(candidate.point, candidate.name)
    }

    useEffect(() => {
        getSuggestions(value)
    }, [value])

    useEffect(() => {
        if (candidate.valid)
            setVisible(true)
        else setVisible(false)
    }, [candidate])

    return <div style={{position: 'relative', width: '100%', maxWidth: 817, display: 'flex', flexDirection: 'column',}}>
        <div style={{
            marginBottom: visible ? '45px' : '0',
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            maxWidth: 817,
            position: 'relative',
        }}>
            <img src={mapPin} style={{marginRight: width > 475 ? '14px' : '2px',}}/>
            <input value={value} onChange={e => {
                setValue(e.target.value);
                setSuggestionsVisible(true)
            }}
                   placeholder={'Введите ваш адрес, пожалуйста'}
                   className={styles.inputSearch} type="text"
                   onBlur={() => setTimeout(() => setSuggestionsVisible(false), 200)}
            />
            {candidate.valid ? <img className={styles.tick} src={tick}/> : null}
        </div>
        <div style={{alignSelf: 'flex-end', marginTop: '3px'}}>
            <SText fontWeight={700} fontSize={12}
                   color={Colors.red}>{(!candidate.name || candidate.valid) ? '' : 'Мы не доставляем в выбранный регион'}</SText>
        </div>
        {suggestions.length !== 0 && suggestionsVisible && <div
            className={cn(styles.suggestionsList, width > 475 ? styles.suggestDesc : styles.suggestMob)}>{suggestions.map(suggestion => {
            return <TouchableOpacity className={styles.suggestionItem}
                                     onClick={() => handleSuggestionSelect(suggestion.name, suggestion.point)}>
                <SText fontSize={18} fontWeight={400}>
                    {suggestion?.name}
                </SText>
            </TouchableOpacity>
        })}</div>}
    </div>
}

const Calculation = () => {

    const {windowDimensions: {width}} = useWindowDimensions()

    const [visible, setVisible] = useState(false)

    return <div style={{paddingBottom: !visible ? '25px' : width >= 475 ? '85px' : '38px'}} className={styles.calc}>
        <div style={width > 475 ? {marginBottom: '18px'} : {marginLeft: '16px', marginBottom: '18px'}}><SText
            fontSize={width > 475 ? 18 : 16} fontWeight={900}>Расчёт стоимости доставки</SText>
        </div>
        <GeoInput visible={visible} setVisible={setVisible}/>
        {visible
            ? <div className={styles.deliveryWrapper}>
                <div className={styles.aboutPrice}>
                    <div className={styles.sum}>
                        <SText style={{marginBottom: 20}} fontSize={14} fontWeight={400} lineHeight={15}
                               color={'#5a5a5a'}>Сумма заказа:</SText>
                        <SText style={{marginBottom: 20}} fontSize={16} fontWeight={700} lineHeight={16}
                               color={'#5a5a5a'}>3000 - 4000 р</SText>
                        <SText fontSize={16} fontWeight={700} lineHeight={16} color={'#5a5a5a'}>от 4000 р</SText>
                    </div>
                    <div className={styles.sumPrice}>
                        <SText style={{marginBottom: 20}} fontSize={14} fontWeight={400} lineHeight={15}
                               color={'#5a5a5a'}>Цена доставки:</SText>
                        <SText style={{marginBottom: 20}} fontSize={16} fontWeight={700} lineHeight={16}
                               color={'#00A469'}>150 р</SText>
                        <SText fontSize={16} fontWeight={700} lineHeight={16} color={'#00A469'}>Бесплатно</SText>
                    </div>
                </div>
                {width >= 789 ? <div className={styles.verticalLine}/> : <div className={styles.horizontalLine}/>}
                <div>
                    <div style={{marginBottom: '20px'}}><SText fontSize={14} fontWeight={400} lineHeight={15}
                                                               color={'#5a5a5a'}>Стоимость и интервалы по дням
                        недели:</SText></div>
                    <div>
                        <Interval day={'Понедельник'} price={'бесплатно'}/>
                        <Interval day={'Вторник'} price={'бесплатно'}/>
                        <Interval day={'Среда'} price={'бесплатно'}/>
                        <Interval day={'Четверг'} price={'бесплатно'}/>
                        <Interval day={'Пятница'} price={'бесплатно'}/>
                        <Interval day={'Суббота'} price={'бесплатно'}/>
                        <Interval day={'Воскресенье'} price={'бесплатно'}/>
                    </div>
                </div>
            </div>
            : null
        }
    </div>
}

const StaticInfo = () => {

    const {windowDimensions: {width}} = useWindowDimensions()
    const regionData = useContext(RegionContext)
    debugger

    return <div className={styles.infoWrapper}>

        <div style={{margin: '20px 0', width: width <= 500 ? '100%' : 500}}>
            <a href={regionData.region == 78 ? "https://yandex.ru/maps/?um=constructor%3A5f4127c3f90ace8825b996925803c34dd13abb1b81a4f7f088e7651dd02aa6a7&amp;source=constructorStatic" : "https://yandex.ru/maps/?um=constructor%3A7097259d9d535556ba6e0ad4e2b25dc39ab1ca876aa25e3674266b7f951b7ef4&amp;source=constructorStatic"}
               target="_blank"><img
                src={regionData.region == 78 ? "https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A5f4127c3f90ace8825b996925803c34dd13abb1b81a4f7f088e7651dd02aa6a7&amp;width=600&amp;height=450&amp;lang=ru_RU" : "https://api-maps.yandex.ru/services/constructor/1.0/static/?um=constructor%3A7097259d9d535556ba6e0ad4e2b25dc39ab1ca876aa25e3674266b7f951b7ef4&amp;width=500&amp;height=400&amp;lang=ru_RU"}
                alt="" style={{border: 0, width: '100%'}}/></a>
        </div>

        <div className={styles.payment}>
            <div style={{marginBottom: '20px'}}><SText fontSize={24} fontWeight={900}>Оплата</SText></div>

            <div style={{marginBottom: '10px'}}><SText fontSize={14} fontWeight={400}>Минимальная сумма заказа - 3000
                рублей</SText></div>
            <div style={{marginBottom: '40px'}}><SText fontSize={14} fontWeight={400}>Бесплатная доставка от 4000 рублей</SText></div>
            <div style={{marginBottom: '18px'}}><SText fontSize={18} fontWeight={700}>Способы оплаты:</SText></div>
            <div>
                <ul>
                    <li className={styles.li}><SText fontSize={14} fontWeight={400}>Банковской картой при оформлении
                        заказа в
                        интернет-магазине.</SText></li>
                    <li className={styles.li}><SText fontSize={14} fontWeight={400}>Оплата курьеру наличными.</SText>
                    </li>
                    <li className={styles.li}><SText fontSize={14} fontWeight={400}>Оплата курьеру картой с помощью QR-
                        кода на накладной
                        или по ссылке.</SText></li>
                </ul>
            </div>
        </div>
        <div className={styles.hr}/>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
            <div style={width > 1000 ? {margin: '0 100px 23px 0'} : {margin: '0 0 18px 0'}}>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Как мы доставляем заказ?</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Все автомобили доставки оборудованы
                        специальными
                        климатическими установками (рефрижераторами) для доставки продуктов. Это сохраняет свежесть и
                        натуральный вкус у наших фермерских продуктов!

                        Курьер позвонит вам по телефону непосредственно перед приездом.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Сертификаты качества</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>По запросу мы можем отправить сертификаты
                        качества и халяль вам на почту.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Упаковка продуктов:</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Для каждого заказа одноразовая
                        -чистая тара:
                        крафтовый короб или пакет, которые пригодятся в хозяйстве. Продукты упакованы либо в термические вакуумные
                        пакеты, либо в бумажные пакеты, а на каждом продукте мы подписываем точный срок
                        годности.</SText>
                </div>
            </div>
            <div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Вес продуктов</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Мы фасуем продукты индивидуально под заказ,
                        поэтому возможна
                        погрешность по весу и изменение цены в пределах 10%.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Цены и комплектация заказа</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Вы видите цены и вес на каждом продукте. В
                        каждый заказ мы
                        вкладываем накладную с составом корзины и деталями заказа.</SText>
                </div>
                <div className={styles.info}>
                    <SText style={{marginBottom: 16}} fontSize={18} fontWeight={700}>Гарантия доброго отношения:</SText>
                    <SText lineHeight={16} fontSize={14} fontWeight={400}>Если вам не понравится качество или свежесть
                        продукта вы
                        можете отказаться от него при приеме заказа. Позвоните нашему менеджеру, и мы вернем вам 100%
                        стоимости продукта. <br/> По вашему запросу мы бесплатно заменим продукт, если с ним что-то
                        случилось при доставке.</SText>
                </div>
            </div>
        </div>
    </div>
}

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions()
    const breadCrumbs = [
        {
            name: 'Доставка и оплата',
            to: '/delivery-and-payment'
        }
    ]

    return <div style={{background: '#FCFCFE'}}>
        <Helmet>
            <title>Доставка и оплата</title>
            <meta name="description" content='Доставка и оплата'/>
            <meta name="keywords" content='Доставка и оплата'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.container}>
            <BreadCrumbs _items={breadCrumbs}/>
            <div style={{marginBottom: '14px'}}><SText fontSize={24} fontWeight={900}>Доставка</SText></div>
            <div className={styles.green}>
                {
                    width >= 425
                        ? <SText fontSize={16} fontWeight={900} color={'#fff'}>Ежедневно, 7 дней в
                            неделю, в&nbsp;выходные и
                            праздники, круглосуточно.</SText>
                        : <SText fontSize={16} fontWeight={900} color={'#fff'}>Ежедневно, 7 дней в
                            неделю, в&nbsp;выходные и
                            праздники, <br/> круглосуточно.</SText>
                }
            </div>
            <Calculation/>
            <StaticInfo/>
        </div>
    </div>
}
