import React, {useEffect, useState} from "react";

const shape = {
    currentId: false,
    manipulate: ()=>{},
}

const ManipulateContext = React.createContext(shape)

export const useManipulateContext = () => {
    const [state, setState] = useState(shape)

    useEffect(()=>{
        const listener = () => {
            setState({currentId: false})
        }
        window.addEventListener('scroll', listener)
        return ()=>{
            window.removeEventListener('scroll', listener)
        }
    },[])

    function manipulate (id) {
        setState({currentId: id})
    }

    return {...state, manipulate}
}

export default ManipulateContext