import axios from 'axios'
import {BASE_URL, GeocoderKeys} from "../config";


const api = axios.create({baseURL: BASE_URL})

// возвращает куки с указанным name,
// или undefined, если ничего не найдено
function getCookie(name) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}

async function createHeaders() {
    const token = localStorage.getItem('HFtoken')

    if (!token) return {}
    return {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
}

function data(response) {
    if (response?.data?.data) {
        return response?.data?.data;
    } else
        return console.log({response: response?.data?.message}) || false
}

export async function requestConfirmationCode(phone = '', isAgreed = true, device = 'web') {
    console.log('SMS requested');
    return await api.post(`auth`, {
        phone: phone,//.replace('+7', '8').replace(/[(|)|\-| ]/g, ''),
        adv_messages: isAgreed,
        device
    }).then(data)
}

export async function submitAuthCode(code, token) {
/*
    const uuid = JSON.parse(localStorage.getItem("_ym_uid"))
*/
    const uuid = getCookie('mindboxDeviceUUID')
    return await api.post('auth/check-code', {code, token, uuid}).then(data)
}

export async function getCatalogue(region) {
    try {
        return await api.get(`store/${region}/catalog`).then(data);
    } catch (e) {
        console.warn(e);
    }
}

export async function getAddressSuggestions(address = '', myCoords) {
    if (address === '') return []
    const response = await axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=${GeocoderKeys[0]}&geocode=${address}&format=json&kind=house`).then(r => r.data)
    //const suggestions = await Suggest.suggestWithCoords(address)
    return response /*(suggestions || []).map(item=>({
    name: extractCity(item.subtitle)+', '+item.title,
    point: {
      lat: item.lat,
      lon: item.lon
    }
  })) */
}

export async function getCart(region = 77) {
    //return {productList: []}
    const headers = await createHeaders()
    if (!headers) return null
    try {
        return await api.post('basket',{region, source: 0}, headers).then(data)
    } catch (e) {
        return await api.get('basket', headers).then(data)
    }

}

export async function updateCard(productId, quantity, region = 77) {
    const headers = await createHeaders()
    return await api.post('basket/update', {products: {productId: Number(productId), quantity}, region, source: 0}, headers).then(data)
}

export async function pushCart(products = [], region = 77) {
    const headers = await createHeaders()
    return await api.post('basket/insert', {products, region, source: 0}, headers).then(data)
}

export async function getProductById(region, productId) {
    const url = `store/${region}/product/${productId}`
    console.log({url});
    return api.get(url).then(data)
}

export async function validateLocation(lat, lon) {
    return await api.post(`user/validateLocation`, {lat, lon}).then(data)
}

export async function setLocation(point, address) {
    const headers = await createHeaders()
    if (!headers) return null
    return await api.post('user/setLocation', {lat: point.lat, lon: point.lon, address}, headers).then(data)
}

export async function getUserInfo() {
    const headers = await createHeaders()
    if (!headers) return null
    return await api.get('user', headers).then(data)
}

export async function updateUserInfo(data) {
    const headers = await createHeaders()
    if (!headers) return null
    /*
    const uuid = JSON.parse(localStorage.getItem("_ym_uid"))
*/
    const uuid = getCookie('mindboxDeviceUUID')
    return await api.post('user/update', {uuid, ...data}, headers).then(data)
}

export async function getBanners() {
    return await api.get('banners/get').then(data)
}

export async function makeOrder ({region, paymentType = 1, scores = 0, address_id, deliverySlot = {}}) {
  const headers = await createHeaders()
  if (!headers) return null
    /*
    const uuid = JSON.parse(localStorage.getItem("_ym_uid"))
*/
    const uuid = getCookie('mindboxDeviceUUID')

    return await api.post('order/create',
        {
            type_payment: paymentType,
            deliverySlot,
            scores,
            region,
            address_id,
            uuid,
        },
        headers).then(data)
}

export async function getGeoSuggestions (string) {
  const url = `https://geocode-maps.yandex.ru/1.x?geocode=${string}&apikey=${GeocoderKeys[0]}&kind=house&rspn=1&bbox=20.742187,26.431228~188.789062,78.903929&format=json`
  const response = await axios.get(url).then(r=>r.data?.response)

    const suggestions = response?.GeoObjectCollection?.featureMember.map(el => {
        const name = el.GeoObject?.metaDataProperty?.GeocoderMetaData?.Address?.formatted
        const point = el.GeoObject?.Point?.pos.split(' ')
        return {
            name: name?.replace('Россия, ', ''),
            point: {
                lat: point[1],
                lon: point[0]
            }
        }
    })
    return suggestions
}

export async function getDeliverySlots(region) {
    const headers = await createHeaders()
    if (!headers) return null
    return api.get(`store/${region}/slots`, headers).then(data)
}

export async function getOrder (id) {
    const headers = await createHeaders()
    if (!headers) return null
    return api.get('user/order/'+id, headers).then(data)
}

export async function searchHints(string, userId='GUEST') {
    return axios.get(`https://search.ecomarket.ru/?id=11608&query=${string}&uid=cc441f080&categories=0&limit=18&location=77`).then(r => r.data)
}

export async function searchAll(string, userId='GUEST'){
    return axios.get(`https://search.ecomarket.ru/?id=11608&query=${string}&uid=cc441f080&categories=0&limit=18&location=77`).then(r => r.data)
}

export async function clearSearchHistory(userId='GUEST') {
    return axios.delete(`https://search.ecomarket.ru/history?id=11608&uid=SM_${userId}`).then(r=>r.data)
}

export async function createAddress(payload = {
    address: '',
    number: '',
    door: '',
    floor: '',
    comment: '',
    lat: '',
    lon: ''
}) {
    const headers = await createHeaders()
    if (!headers) return null
    return api.post('user/createAddress', payload, headers).then(data)
}

export async function appendPromoCode (region, code) {
    const headers = await createHeaders()
    if (!headers) return null
    return api.post('basket/promocode', {region, promocode: code}, headers).then(response=>{
        debugger
        if (response?.data?.data) {
            return response?.data?.data;
        } else
            return response?.data
    })
}

export async function removePromoCode (region) {
    const headers = await createHeaders()
    if (!headers) return null
    const uuid = getCookie('mindboxDeviceUUID')
    return api.post('basket/promocode/delete', {region, uuid}, headers).then(data)
}

export async function informAboutAdmission (productId) {
    const headers = await createHeaders()
    if (!headers) return null
    return api.post('/user/subscribe', {type: 0, product_id: productId}, headers)
}

export async function updateAddress(payload = {
    id: 0,
    address: '',
    number: '',
    door: '',
    floor: '',
    comment: '',
    lat: '',
    lon: ''
}) {
    const headers = await createHeaders()
    if (!headers) return null
    return api.post('user/updateAddress', payload, headers).then(data)
}

export async function getOrderToPayInfo (orderId) {
    return await api.post('payment/order/pay', {order_id: orderId}).then(data)
}

export async function unsubscribe (token,reasons) {
    return await api.post('user/unsubscribe', {token,reason:reasons}).then(data)
}

function extractCity(subtitle) {
    const array = subtitle.split(',').map(w => w.trim())

    return array.filter((_, i, a) => i !== a.length - 1).join(', ') || subtitle
}
