import React, {useMemo} from "react";
import SText from "./SText";
import styles from "./AboutSeason.module.css"
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import {Helmet} from "react-helmet";

export default () => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'О HalalFoods',
            to: '/about-season-market'
        }
    ]


    return <div>
        <Helmet>
            <title>О HalalFoods</title>
            <meta name="description" content='О HalalFoods'/>
            <meta name="keywords" content='О HalalFoods'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div style={{marginBottom: '35px'}}><SText fontSize={24} fontWeight={900}>О HalalFoods</SText></div>
                <div style={{marginBottom: '15px'}}><SText fontSize={16} fontWeight={900}>Халяль Фудс – доставка
                    халяльных продуктов по Москве и Московской области всего за 90 минут!</SText></div>
                <div style={{marginBottom: '15px'}}><SText fontSize={16} fontWeight={700}>Заказывая натуральные продукты у нас, можете быть уверены,
                    что они качественные и изготовленные по особой технологии, предписанной шариатом, а также не
                    содержат запрещенных исламом компонентов.</SText></div>
                <div style={{marginBottom: '15px'}}><SText fontSize={16} fontWeight={700}>Больше нет нужды самостоятельно искать продукты и проверять
                    их состав. Мы убедились, что все они соответствуют нормам Халяль и с радостью предлагаем вам только
                    самое лучшее!</SText></div>
                <div><SText fontSize={16} fontWeight={700}>Выбирайте Халяль Фудс – в вашем рационе всегда будет
                    исключительно экологически чистая и полезная еда. С нами – быстро, вкусно и просто!</SText></div>
            </div>
        </div>
    </div>
}