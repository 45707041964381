import React from "react";
import styles from "./BonusSystem.module.css"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import SText from "./SText";

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Икра горбуши',
            to: '/caviar'
        }
    ]

    return <div>
        <Helmet>
            <title>О HalalFoods</title>
            <meta name="description" content='Икра горбуши'/>
            <meta name="keywords" content='Икра горбуши'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div><img style={{maxWidth: 891, marginBottom: 20, width: '100%'}} src={width>=540?"https://api.HalalFoods.ru/images/banners/banner-ikra_v_podarok_do_1512-desk.png":'https://api.HalalFoods.ru/images/banners/banner-ikra_v_podarok_do_1512-mob.png'} alt=""/></div>
                <div style={{marginBottom: width > 762 ? 30 : 30}} className={styles.title}><SText fontSize={24}
                                                                                                   lineHeight={23}
                                                                                                   fontWeight={900}>Икра горбуши</SText></div>

                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Банка красной икры горбуши 100г  за 1 рубль при первом заказе в мобильном приложении от 3 тыс рублей. Чтобы воспользоваться предложением, бонусы не применяются.</SText></div>
                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Добавляйте товары в корзину и совершайте покупку, натуральный деликатес по спецпредложению будет добавлен автоматически.</SText></div>
                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Акция действует до 31 декабря 2021 г. Количество товара ограниченно.</SText></div>


            </div>
        </div>
    </div>
}