import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import styles from "./Ordering.module.css"
import logo from "../../../assets/images/logo.svg"
import logoM from "../../../assets/images/halalMobile.svg"
import SText from "../../../components/SText";
import mapPin from "../../../assets/images/MapPin.svg"
import clock from "../../../assets/images/Clock.svg"
import change from "../../../assets/images/change.svg"
import RoundedButton from "../../../components/RoundedButton";
import Colors from "../../../utils/Colors";
import greenTick from "../../../assets/images/greenTick.png"
import Switch from "../../../components/Switch";
import Input from "../../../components/Input";
import TextareaAutosize from 'react-textarea-autosize';
import {REGEX, useCoolDown, useForm, useWindowDimensions} from "../../../utils/hooks";
import Modal from "../../../components/Modal";
import bankCard from "../../../assets/images/bankCard.svg"
import walletModal from "../../../assets/images/walletModal.svg"
import editPencil from "../../../assets/images/editPencil.svg"
import greenCircle from "../../../assets/images/emptyCircle.png"
import {NavLink, Redirect} from "react-router-dom";
import DataContext from "../../../data/DataContext";
import {getGeoSuggestions, validateLocation} from "../../../api";
import ModalContext from "../../../data/ModalContext";
import ModalHeader from "../../../components/ModalHeader";
import TouchableOpacity from "../../../components/TouchableOpacity";
import Icon from "../../../components/Icon";
import erase from '../../../assets/images/closeButton.svg'
import {circle, text} from "../../../utils/mixins";
import SelectInput from "../../../components/SelectInput";
import moment from "moment";
import {between, capitalize} from "../../../utils/other";
import {dayFormat, inputFormat, targetFormat} from "../../../config";
import {launchPayment} from "../../../utils/payment";
import {Helmet} from "react-helmet";
import {PromoModal} from "../../../components/OrderBasket";
import NewModal from "../../../components/NewModal";
import cross from '../../../assets/images/closeButton.svg'
import closeIcon from "../../../assets/images/closeButton.svg";
import help from "../../../assets/images/help.svg";
import wa from "../../../assets/images/waWhite.svg";
import mail from "../../../assets/images/mailWhite.svg";

export const ModalCard = ({icon, text, setPayment, onSelect, isActive}) => {

    return <div onClick={() => {
        onSelect()
        setPayment(false)
    }} style={{cursor: 'pointer'}} className={isActive ? styles.modalCardActive : styles.modalCard}>
        <div className={styles.modalCardIcon}>
            <div><img src={icon}/></div>
            <div><img src={isActive ? greenTick : greenCircle}/></div>
        </div>
        <div><SText fontSize={14} fontWeight={700}>{text}</SText></div>
    </div>
}

const AddressCard = ({
                         title, onChange = () => {
    }, value
                     }) => {
    const ref = useRef(null)

    return <div onClick={() => {
        ref.current?.focus()
    }} className={styles.addressCard}>
        <div><SText fontSize={12} fontWeight={400} color={'#BDBDBD'}>{title}</SText></div>
        <div><input ref={ref} style={{width: '70px'}} type="text" value={value}
                    onChange={(e) => onChange(e.target.value)}/></div>
    </div>
}

const TimeButton = ({from, to, id, value, onSelect, isSelected}) => {

    function handleSelect() {
        onSelect(value)
    }

    return (isSelected
            ? <div className={styles.timeButton}>
                <SText fontSize={14} fontWeight={400}
                       color={'#FCFCFE'}>с {from} до {to}</SText>
            </div>
            : <div onClick={handleSelect} style={{background: 'transparent', border: '1px solid #213140'}}
                   className={styles.timeButton}>
                <SText
                    fontSize={14} fontWeight={400}
                    color={'#213140'}>с {from} до {to}</SText>
            </div>
    )
}

export const Header = () => {
    const {isMobile} = useWindowDimensions(768)
    const [openPopup, setOpenPopup] = useState(false)

    if (isMobile) {
        return <div style={{background: '#fff', position: 'sticky', top: 0, zIndex: 100}}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <NavLink to={'/'} className={styles.logo}><img src={logoM}/></NavLink>
                </div>
            </div>
        </div>
    } else return <div style={{background: '#fff', position: 'sticky', top: 0, zIndex: 100}}>
        <div className={styles.container}>
            <div className={styles.header}>
                <NavLink to={'/'} className={styles.logo}><img src={logo}/></NavLink>
                <div className={styles.contacts}>
                    <div className={styles.helpBtn} onClick={() => setOpenPopup(prev => !prev)}>
                        <img src={help}/>
                        <SText fontSize={18} fontWeight={700} lineHeight={18} color={Colors.white}>Служба
                            поддержки</SText>
                    </div>
                    {openPopup && <div className={styles.popUp}>
                        <a href="tel:+74956460371">
                            <div className={styles.waBtn} onClick={() => setOpenPopup(false)}>
                                <img src={wa}/>
                                <SText fontSize={16} fontWeight={700} lineHeight={16} color={Colors.white}>Связаться по
                                    WhatsApp</SText>
                            </div>
                        </a>
                        <a href="mailto:support@halalfoods.ru">
                            <div className={styles.mailBtn} onClick={() => setOpenPopup(false)}>
                                <img src={mail}/>
                                <SText fontSize={16} fontWeight={700} lineHeight={16} color={Colors.white}>Связаться по
                                    почте</SText>
                            </div>
                        </a>
                    </div>}
                </div>
            </div>
        </div>
    </div>
}

export const UserInfo = ({error = false}) => {
    const {isMobile} = useWindowDimensions(768)
    const {user} = useContext(DataContext)

    const [wantsMailing, setWantsMailing] = useState(true)
    const [emailError, setEmailError] = useState(false)

    const {getInputProps, _valueOf, _onInputChangeOf} = useForm({
        initialState: {
            name: user.data?.full_name || '', //TODO
            email: user.data?.email || '',
        },
    })

    useEffect(() => {
        _onInputChangeOf('name')(user.data?.full_name)
        _onInputChangeOf('email')(user.data?.email)
    }, [user.data?.full_name, user.data?.email])

    const [name, email] = [_valueOf('name'), _valueOf('email')]

    async function _updateUser(fieldName, value) {
        await user.update({[fieldName]: value})
    }

    const updateUser = useCoolDown(_updateUser)

    useEffect(() => {
        if (name !== '')
            updateUser('full_name', name)
    }, [name])

    useEffect(() => {
        if (email === '') return

        setEmailError(false)
        const error = !REGEX.EMAIL.test(email)
        if (error)
            setEmailError(true)
        else
            updateUser('email', email)

    }, [email])

    if (isMobile) {
        return <div className={styles.userInfo}>
            <div className={styles.name}>
                <Input style={{width: '334px'}} title={'Ваше имя'} {...getInputProps('name')} error={error}/>
            </div>
            {/*            <div className={styles.emailAddress}>
                <div className={styles.email}>
                    <Input style={{minWidth: '334px'}} {...getInputProps('email')}
                           title={'Адрес вашей электронной почты'}/>
                    {emailError && <SText fontWeight={400} fontSize={12} color={Colors.red}>
                        {'Скорее всего это некорректный адрес эл.почты. Проверьте, пожалуйста'}
                    </SText>}
                </div>
                <div onClick={() => {
                    if (wantsMailing) {
                        setWantsMailing(false)
                    } else setWantsMailing(true)
                }
                } className={styles.mailing}>
                    <img style={{marginRight: 11, width: 28, height: 28, opacity: 1}}
                         src={wantsMailing ? greenTick : greenCircle}/>
                    <SText fontSize={14}
                           fontWeight={550}
                           lineHeight={16}
                           color={'#909090'}>Хочу получать рассылку</SText>
                </div>
            </div>*/}
        </div>
    } else return <div>
        <div className={styles.name}>
            <Input style={{minWidth: '334px'}} title={'Ваше имя'} {...getInputProps('name')} error={error}/>
        </div>
        {/*        <div className={styles.emailAddress}>
            <div className={styles.email}>
                <Input style={{minWidth: '334px'}} {...getInputProps('email')} title={'Адрес вашей электронной почты'}/>
                {emailError && <SText fontWeight={400} fontSize={12} color={Colors.red}>
                    {'Скорее всего это некорректный адрес эл.почты. Проверьте, пожалуйста'}
                </SText>}
            </div>
            <div onClick={() => {
                if (wantsMailing) {
                    setWantsMailing(false)
                } else setWantsMailing(true)
            }
            } className={styles.mailing}>
                <img style={{marginRight: 11, width: 28, height: 28}} src={wantsMailing ? greenTick : greenCircle}/>
                <SText fontSize={14}
                       fontWeight={550}
                       lineHeight={16}
                       color={'#909090'}>Хочу получать рассылку</SText>
            </div>
        </div>*/}
    </div>
}

export const AddressSelector = ({
                                    onClose = () => {
                                    }
                                }) => {
    const [value, setValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [candidate, setCandidate] = useState({valid: false})
    const {userLocation} = useContext(DataContext)

    function clear() {
        setValue('')
        setSuggestions([])
    }

    async function _getSuggestions(input) {
        if (input === '')
            return setSuggestions([])

        const response = await getGeoSuggestions(input)
        setSuggestions(response)
    }

    const getSuggestions = useCoolDown(_getSuggestions)

    async function handleSuggestionSelect(name, point) {
        setValue(name)
        const response = await validateLocation(point.lat, point.lon) || false
        setCandidate({
            name, point, valid: response.validate || false
        })
    }

    async function onSubmit() {
        if (!candidate.valid) return

        await userLocation.update({
            lat: candidate.point.lat,
            lon: candidate.point.lon,
            address: candidate.name,
            comment: '',
            floor: '',
            door: '',
            number: ''
        })
        onClose()

    }

    useEffect(() => {
        getSuggestions(value)
    }, [value])

    return <div className={styles.addressModal}>
        <ModalHeader title={'Выбрать адрес'} onClose={onClose}/>
        <div className={styles.inputRow}>
            <Input onChange={setValue} className={styles.geoInput} value={value} title={'Адрес'}/>
            {value !== '' && <TouchableOpacity style={circle(18, 'transparent')} onClick={clear}>
                <Icon iconSrc={erase} size={12}/>
            </TouchableOpacity>}
            <div className={styles.gradient}/>
        </div>
        <div className={styles.suggestionsList}>{suggestions.map(suggestion => {
            return <TouchableOpacity className={styles.suggestionItem}
                                     onClick={() => handleSuggestionSelect(suggestion.name, suggestion.point)}>
                <SText fontSize={18} fontWeight={400}>
                    {suggestion?.name}
                </SText>
            </TouchableOpacity>
        })}</div>
        <div className={styles.controller}>
            <SText fontWeight={700} fontSize={12}
                   color={Colors.red}>{(!candidate.name || candidate.valid) ? '' : 'Мы не доставляем в выбранный регион'}</SText>
            <RoundedButton disabled={!candidate.valid}
                           label={'Подтвердить адрес'}
                           onPress={onSubmit}
                           activeColor={Colors.darkBlue}
                           dimmedColor={Colors.darkBlue + 'CC'}
            />
        </div>
    </div>
}

const AddressInput = ({
                          currentAddress = {point: {}, name: ''}, onSubmitNewAddress = () => {
    }, warning = false
                      }) => {
    const [suggestedPoints, setSuggestedPoints] = useState([])
    const [value, setValue] = useState(currentAddress.name || '')
    const [error, setError] = useState(false)
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)
    const [editState, setEditState] = useState(false)

    const input = useRef(null)

    async function tryCandidate(candidate) {
        setValue(candidate.name)
        const response = await validateLocation(candidate.point.lat, candidate.point.lon || false)
        if (response?.validate) {
            onSubmitNewAddress(candidate.name, candidate.point)
            setSuggestionsVisible(false)
            setEditState(false)
        } else {
            setError(true)
        }
    }

    async function _fetchSuggestions() {
        if (value === '')
            return setSuggestedPoints([])

        const response = await getGeoSuggestions(value)
        setSuggestedPoints(response)
    }

    const fetchSuggestions = useCoolDown(_fetchSuggestions)

    useEffect(() => {
        setError(false)
        fetchSuggestions()
    }, [value])

    function listener(e) {
        if (document.querySelector('.' + styles.addressInputNew)?.contains(e.target) ||
            document.querySelector('.' + styles.addressDropDown)?.contains(e.target) ||
            document.querySelector('.eraseButton')?.contains(e.target)
        ) {

        } else {
            if (editState) {
                setSuggestionsVisible(false)
                setEditState(false)
            }
        }
    }

    useEffect(() => {
        if (editState) {
            setValue(currentAddress.name || '')
            input.current?.focus()
        }
        window.addEventListener('mousedown', listener)
        return () => {
            window.removeEventListener('mousedown', listener)
        }
    }, [editState])

    if (!editState) return <div className={styles.addressInput} onClick={() => setEditState(true)}>
        <SText fontSize={18} fontWeight={700}
               color={warning ? Colors.red : Colors.darkBlue}>{currentAddress.name || 'Укажите адрес доставки'}</SText>
        <div style={{...circle(26), marginLeft: 26}}>
            <Icon iconSrc={editPencil}/>
        </div>
    </div>

    return <div className={styles.addressContainer}>
        {error && <SText color={Colors.red}
                         fontWeight={550}
                         fontSize={12}
                         className={styles.addressError}>{'Мы не доставляем на этот адрес'}</SText>}
        <div style={{position: 'relative'}} id={'addressInputContainer'}>
            <input value={value}
                   ref={input}
                   onFocus={() => setSuggestionsVisible(true)}
                   onClick={() => setSuggestionsVisible(true)}
                   placeholder={'Укажите адрес доставки'}
                   className={styles.addressInputNew}
                   onChange={e => setValue(e.target.value)}/>
            {value !== '' &&
                <div className={'eraseButton'} style={{position: "absolute", right: 16, top: 14, opacity: .3}}
                     onClick={() => {
                         setValue('')
                         input.current?.focus()
                     }}>
                    <Icon iconSrc={cross} size={16}/>
                </div>}
        </div>
        {suggestionsVisible && !!suggestedPoints.length && <div className={styles.addressDropDown}>
            {suggestedPoints.map(suggestion => {
                if (suggestion.precision === 'other') return null
                return <TouchableOpacity
                    onClick={() => tryCandidate(suggestion)}
                    className={styles.suggestionItemNew}
                >
                    <SText fontSize={16} fontWeight={400}>{suggestion.name}</SText>
                </TouchableOpacity>
            })}
        </div>}
    </div>
}

export const Delivery = ({
                             account,
                             dayOptions = [],
                             timeOptions = [],
                             selectedDay,
                             onDaySelect,
                             error = false,
                             setSelectedInterval,
                             selectedInterval
                         }) => {

    const {isMobile} = useWindowDimensions(768)
    const {userLocation} = useContext(DataContext)
    const {open} = useContext(ModalContext)

    const [dataLoaded, setDataLoaded] = useState(false)

    const _onUpdate = (data) => {
        userLocation.update(data)
    }

    const onUpdate = useCoolDown(_onUpdate, 1500)

    const {getInputProps, _onInputChangeOf, _valueOf} = useForm({})

    useEffect(() => {
        if (userLocation.data) {
            _onInputChangeOf('comment')(userLocation.data?.comment || '')
            _onInputChangeOf('floor')(userLocation.data?.floor || '')
            _onInputChangeOf('number')(userLocation.data?.number || '')
            _onInputChangeOf('door')(userLocation.data?.door || '')
            setDataLoaded(true)
        }
    }, [
        userLocation.data?.comment,
        userLocation.data?.floor,
        userLocation.data?.number,
        userLocation.data?.door
    ])

    useEffect(() => {
        if (dataLoaded)
            onUpdate({
                comment: _valueOf('comment'),
                floor: _valueOf('floor'),
                door: _valueOf('door'),
                number: _valueOf('number'),
            })
    }, [
        _valueOf('comment'),
        _valueOf('floor'),
        _valueOf('door'),
        _valueOf('number')
    ])


    const {address, isAddressStated} = {
        address: userLocation.data?.address || false,
        isAddressStated: !!userLocation.data?.address
    }

    const ref = useRef(null)

    async function onAddressSubmit(name, point) {
        await userLocation.update({
            lat: point.lat,
            lon: point.lon,
            address: name,
            comment: '',
            floor: '',
            door: '',
            number: ''
        })

    }

    //const [focusedName, setFocusedName] = useState(false)

    const [activeDeliveryTime, setActiveDeliveryTime] = useState(0)

    if (isMobile) {
        return <div style={account ? {padding: '5px 20px 0'} : {padding: '5px 20px 30px'}}>
            <div style={{marginBottom: '20px'}}>
                <SText fontSize={20} fontWeight={900}
                       color={'#213140'}>Доставка:</SText>
            </div>
            <div className={styles.address}>
                <div style={{marginLeft: '-5px', marginRight: '11px'}}>
                    <img src={mapPin}/>
                </div>
                <AddressInput currentAddress={{name: address, point: userLocation.data?.point}}
                              warning={error}
                              onSubmitNewAddress={onAddressSubmit}/>
            </div>

            <div style={{marginBottom: '37px'}} className={styles.deliveryDetails}>
                <div style={{display: 'flex'}}>
                    <AddressCard title={'Кв/офис'} {...getInputProps('number')} />
                    <AddressCard title={'Подъезд'} {...getInputProps('door')}   />
                    <AddressCard title={'Этаж'}    {...getInputProps('floor')}  />
                </div>
                <div onClick={() => {
                    ref.current?.focus()
                }} style={{margin: '0'}} className={styles.commentCard}>
                    <div><SText fontSize={12} fontWeight={400} color={'#BDBDBD'}>Комментарий к заказу</SText></div>
                    <div><TextareaAutosize ref={ref} className={styles.commentInput} minRows={2} maxRows={5}
                                           value={_valueOf('comment')}
                                           onChange={(e) => _onInputChangeOf('comment')(e.target.value)}
                                           draggable={false} type="text"/></div>
                </div>
            </div>

            {account ?
                null :
                <div>
                    {isAddressStated ?
                        <div style={{marginLeft: 31}}>
                            <div style={{marginBottom: 21}}>
                                <SText fontSize={14} fontWeight={400} color={'#909090'}>
                                    {'Выберите дату доставки'}
                                </SText>
                                <div style={{height: 16}}/>
                                <SelectInput options={dayOptions} value={selectedDay} onSelect={onDaySelect}/>
                            </div>
                            <div style={{marginBottom: 16}}>
                                <SText fontSize={14} fontWeight={400} color={'#909090'}>
                                    {'Выберите время доставки'}
                                </SText>
                            </div>
                            <div className={styles.intervalsList}>
                                {timeOptions.map((time, index) => <TimeButton onSelect={setSelectedInterval}
                                                                              isSelected={selectedInterval === time.value}
                                                                              key={index}
                                                                              value={time.value}
                                                                              from={time.from} to={time.to}/>)}
                            </div>
                        </div>
                        :
                        <div className={styles.deliveryTime}>
                            <div style={{opacity: '0.6', marginLeft: '-5px', marginRight: '16px'}}>
                                <img src={clock}/>
                            </div>
                            <div style={{maxWidth: '305px', marginTop: '-3px'}}>
                                <SText fontSize={14} fontWeight={400}
                                       color={'#909090'}>
                                    {'После ввода адреса, мы скажем ближайшее время доставки'}
                                </SText>
                            </div>
                        </div>}
                </div>}
        </div>
    } else return <div>
        {account
            ? <div style={{marginBottom: '20px'}}><SText fontSize={14} fontWeight={400}
                                                         color={'#909090'}>Адрес доставки</SText></div>
            : <div style={{marginBottom: '20px'}}><SText fontSize={20} fontWeight={900}
                                                         color={'#213140'}>Доставка:</SText></div>}

        <div className={styles.address}>
            {account ? null :
                <div style={{marginLeft: '-5px', marginRight: '16px'}}>
                    <img src={mapPin}/></div>}
            <AddressInput currentAddress={{name: address, point: userLocation.data?.point}}
                          warning={error}
                          onSubmitNewAddress={onAddressSubmit}/>
        </div>

        <div style={account ? {marginLeft: '0'} : {}} className={styles.deliveryDetails}>
            <div style={{display: 'flex'}}>
                <AddressCard {...getInputProps('number')} title={'Кв/офис'}/>
                <AddressCard {...getInputProps('door')} title={'Подъезд'}/>
                <AddressCard {...getInputProps('floor')} title={'Этаж'}/>
            </div>
            <div onClick={() => {
                ref.current?.focus()
            }} className={styles.commentCard}>
                <div><SText fontSize={12} fontWeight={400} color={'#BDBDBD'}>Комментарий к заказу</SText></div>
                <div><TextareaAutosize ref={ref} className={styles.commentInput} minRows={2} maxRows={5}
                                       value={_valueOf('comment')}
                                       onChange={(e) => _onInputChangeOf('comment')(e.target.value)}
                                       draggable={false} type="text"/></div>
            </div>
        </div>

        {account
            ? null
            : <div>
                {isAddressStated ?
                    <div>
                        <div className={styles.selectDeliveryTime}>
                            <div><img src={clock}/></div>
                            <div>
                                <SText fontSize={18} fontWeight={700}>
                                    {'Доставка'}
                                </SText>
                            </div>
                        </div>
                        <div style={{marginLeft: 50}}>
                            <div style={{marginBottom: 21}}>
                                <SText fontSize={14} fontWeight={400} color={'#909090'}>
                                    {'Выберите дату доставки'}
                                </SText>
                                <div style={{height: 16}}/>
                                <SelectInput options={dayOptions} value={selectedDay} onSelect={onDaySelect}/>
                            </div>
                            <div style={{marginBottom: 16}}>
                                <SText fontSize={14} fontWeight={400} color={'#909090'}>
                                    {'Выберите время доставки'}
                                </SText>
                            </div>
                            <div className={styles.intervalsList}>
                                {timeOptions.map((time, index) => <TimeButton onSelect={setSelectedInterval}
                                                                              isSelected={selectedInterval === time.value}
                                                                              key={index}
                                                                              value={time.value}
                                                                              from={time.from} to={time.to}/>)}
                            </div>
                        </div>
                    </div>
                    : // if no address
                    <div className={styles.deliveryTime}>
                        <div style={{opacity: '0.6', marginLeft: '-5px', marginRight: '16px'}}>
                            <img src={clock}/>
                        </div>
                        <div style={{maxWidth: '305px', marginTop: '-3px'}}>
                            <SText fontSize={14} fontWeight={400}
                                   color={'#909090'}>
                                {'После ввода адреса, мы скажем ближайшее время доставки'}
                            </SText>
                        </div>
                    </div>}
            </div>}
    </div>
}

const Payment = ({
                     onMakeOrder, cartTotal = 0, activeMethod, setMethod = () => {
    }
                 }) => {

    const {isMobile} = useWindowDimensions(768)
    const {cart} = useContext(DataContext)

    const [payment, setPayment] = useState(false)
    const [activeId, setActiveId] = useState(3)

    if (isMobile) {
        return <div className={styles.paymentBlock}>
            <div style={{marginBottom: '6px'}}><SText fontSize={14} fontWeight={550}>Способ оплаты</SText>
            </div>
            <div className={styles.payment}>

                <div onClick={() => setPayment(true)} className={styles.selectedPayment}>
                    <div style={{marginRight: '16px'}}><img src={cards.find(card => card.id == activeMethod)?.icon}/>
                    </div>
                    <div style={{marginRight: '8px'}}><SText fontSize={16} fontWeight={700}
                                                             color={'#213140'}>{cards.find(card => card.id == activeMethod)?.text}</SText>
                    </div>
                </div>
                {payment
                    ? <div className={styles.changePayment}>
                        <div><img src={change}/></div>

                        <NewModal isVisible={payment} onClose={() => setPayment(false)} minWidth={303} minHeight={511}
                                  fullScreen={false}>
                            <div style={{padding: '18px 14px 33px 16px'}}>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 45}}>
                                    <div style={{
                                        fontSize: 16,
                                        fontWeight: 900,
                                        color: '#213140',
                                        fontFamily: 'HelveticaNeueCyr-Black'
                                    }}>ВЫБОР СПОСОБА <br/> ОПЛАТЫ
                                    </div>
                                    <TouchableOpacity onClick={() => setPayment(false)}>
                                        <div style={{
                                            background: '#F5F5F5',
                                            borderRadius: '50%',
                                            width: 26,
                                            height: 26,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <img style={{width: 14, height: 14}} src={closeIcon}/>
                                        </div>
                                    </TouchableOpacity>
                                </div>
                                <div>
                                    {cards.map(card => <ModalCard setPayment={setPayment} key={card.id}
                                                                  setActiveId={setActiveId}
                                                                  onSelect={() => setMethod(card.id)}
                                                                  isActive={activeMethod === card.id}
                                                                  activeId={activeId} id={card.id} icon={card.icon}
                                                                  text={card.text}/>)}
                                </div>
                            </div>
                        </NewModal>
                    </div>
                    : <div onClick={() => setPayment(true)} className={styles.changePayment}>
                        <div><img src={change}/></div>
                    </div>
                }
            </div>
        </div>
    } else return <div>
        <div style={{marginBottom: '22px'}}><SText fontSize={20} fontWeight={900}>СПОСОБ ОПЛАТЫ:</SText>
        </div>
        <div className={styles.payment}>

            <div onClick={() => setPayment(true)} className={styles.selectedPayment}>
                <div style={{marginRight: '16px'}}><img src={cards.find(card => card.id == activeMethod)?.icon}/></div>
                <div style={{marginRight: '50px'}}><SText fontSize={16} fontWeight={700}
                                                          color={'#213140'}>{cards.find(card => card.id == activeMethod)?.text}</SText>
                </div>
            </div>
            {payment
                ? <div className={styles.changePayment}>
                    <div style={{marginRight: '12px'}}><SText fontSize={14} fontWeight={400}
                                                              color={'#909090'}>Изменить</SText></div>
                    <div><img src={change}/></div>

                    <Modal modalTitle={'ВЫБОР СПОСОБА ОПЛАТЫ'} isVisible={payment}
                           onClose={() => setPayment(false)}>
                        <div>
                            {cards.map(card => <ModalCard setPayment={setPayment} key={card.id}
                                                          setActiveId={setActiveId}
                                                          onSelect={() => setMethod(card.id)}
                                                          isActive={activeMethod === card.id}
                                                          activeId={activeId} id={card.id} icon={card.icon}
                                                          text={card.text}/>)}
                        </div>
                    </Modal>
                </div>
                : <div onClick={() => setPayment(true)} className={styles.changePayment}>
                    <div style={{marginRight: '12px'}}>
                        <SText fontSize={14} fontWeight={400} color={'#909090'}>
                            {'Изменить'}
                        </SText>
                    </div>
                    <div>
                        <img src={change}/>
                    </div>
                </div>
            }
        </div>
        <div className={styles.payButton}>
            <RoundedButton activeColor={Colors.darkBlue}
                           onPress={onMakeOrder}
                           containerStyle={{justifyContent: 'space-between', padding: '0 20px'}}>
                <SText color={'#fff'} fontWeight={550} fontSize={18}>
                    {activeMethod == 1 ? 'Оформить' : 'Оплатить'}
                </SText>
                <div>
                    <SText color={'#fff'} fontWeight={550} fontSize={18}>
                        <strong>{cartTotal}</strong>
                    </SText>
                    <SText fontSize={15} fontWeight={700} color={'#fff'} style={{fontWeight: 700}}>{'\u00A0₽'}</SText>
                </div>

            </RoundedButton>
        </div>
    </div>
}

const Promo = ({
                   onChange = () => {
                   }
               }) => {
    const {cart, user} = useContext(DataContext)

    const [isVisible, setIsVisible] = useState(false)
    const {isMobile} = useWindowDimensions(992)
    const [useScores, setUseScores] = useState(false)
    const [scores, setScores] = useState(Math.min(user.data?.scores, Math.floor(cart.data?.basketAmount / 2)))

    useEffect(() => {
        onChange(scores)
    }, [scores])

    useEffect(() => {
        if (cart.data?.promocode && cart.data?.promocode != '') {
            setScores(0)
            setUseScores(false)
        }
    }, [cart.data?.promocode])

    useEffect(() => {
        if (!useScores) {
            setScores(0)
        } else {
            setScores(Math.min(user.data?.scores, Math.floor(cart.data?.basketAmount / 2)))
        }
    }, [useScores])

    useEffect(() => {
    }, [cart.data?.basketAmount])


    return <>
        {isMobile ?
            <NewModal onClose={() => setIsVisible(false)} isVisible={isVisible} modalTitle={'ПРОМОКОД'} maxHeight={323}
                      fullScreen={false}
                      maxWidth={303}>
                <PromoModal onClose={() => setIsVisible(false)}/>
            </NewModal> :
            <Modal isVisible={isVisible} onClose={() => setIsVisible(false)} modalTitle={'ПРОМОКОД'} maxHeight={375}
                   maxWidth={475}>
                <PromoModal/>
            </Modal>}
        <div className={styles.promoCard}>
            <div onClick={() => {
                if (!useScores)
                    setIsVisible(true)
            }} style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '18px',
                cursor: 'pointer',
                opacity: useScores ? .6 : 1
            }}>
                <div><SText fontSize={18} fontWeight={700}>Промокод</SText></div>
                <div>
                    <img style={{width: 26, height: 26}} src={cart.data?.promocode ? greenTick : change}/>
                </div>
            </div>
            <div style={{maxWidth: '240px', paddingBottom: '30px', lineHeight: '14px'}}>
                <SText fontSize={14}
                       fontWeight={400}
                       color={'#909090'}>{`Вы можете применить промокод или использовать бонусы`}
                </SText>
            </div>
            <div>
                <div style={{display: 'flex', alignItems: 'center', opacity: cart.data?.promocode ? .6 : 1}}>
                    <div style={{display: 'flex', alignItems: 'center', flex: 1}}>
                        <div style={{marginRight: '10px', flex: 1}}>
                            <SText fontSize={16} fontWeight={550}>{'Потратить баллы'}</SText>
                        </div>
                        <input className={styles.points}
                               value={useScores ? Number(scores).toString() : Math.min(user.data?.scores, Math.floor(cart.data?.basketAmount / 2))}
                               type={'number'}
                               onChange={(e) => setScores(between(0, Number(e.target.value), Math.min(Math.floor(cart.data.basketAmount / 2), user.data.scores)))}
                               contentEditable style={text(18, 700)}>
                        </input>
                    </div>
                    <div>
                        <Switch isOn={useScores} disabled={cart.data?.promocode} onToggle={setUseScores}/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const Price = ({total = 0, activeMethod, delivery = 0, scores = 0, onMakeOrder, isMobile = false}) => {

    const {cart} = useContext(DataContext)

    return <div className={styles.priceCard}>
        {/* <div>
            <div><SText fontSize={16} fontWeight={500}>Стоимость товаров</SText></div>
            <div><SText fontSize={18} fontWeight={700}>{'22 656'}</SText></div>
        </div>*/}
        <div style={{marginBottom: 10}}>
            <div><SText fontSize={16} fontWeight={500}>Стоимость товаров</SText></div>
            <div><SText fontSize={18}
                        fontWeight={700}>{(cart.data?.basketAmount + cart.data?.amount_bonuses).toFixed(2)}</SText>
                <SText fontSize={15} fontWeight={700} style={{fontWeight: 700}}>{'\u00A0₽'}</SText>
            </div>
        </div>
        <div style={{marginBottom: 10}}>
            <div><SText fontSize={16} fontWeight={500}>Стоимость доставки</SText></div>
            <div><SText fontSize={18}
                        fontWeight={700}>{delivery == 0 ? 'Бесплатно' : delivery}</SText> {delivery != 0 && <SText
                fontSize={15} fontWeight={700} style={{fontWeight: 700}}>{'\u00A0₽'}</SText>}</div>
        </div>
        {cart.data?.amount_bonuses + scores > 0 && <div style={{marginBottom: 30}}>
            <div><SText fontSize={16} lineHeight={18} fontWeight={500} color={Colors.mainGreen}>Скидка</SText></div>
            <div><SText fontSize={18} lineHeight={16} fontWeight={700}

                        color={Colors.mainGreen}>-{cart.data?.amount_bonuses + scores}</SText> <SText fontSize={15}
                                                                                                      fontWeight={700}
                                                                                                      color={Colors.mainGreen}
                                                                                                      style={{fontWeight: 700}}>{'\u00A0₽'}</SText>

            </div>
        </div>}
        {/*
        <div style={{paddingBottom: '11px'}}>
            <div><SText fontSize={16} fontWeight={500} color={Colors.mainGreen}>Скидка</SText></div>
            <div><SText fontSize={18} fontWeight={700} color={Colors.mainGreen}>{'-656'}</SText></div>
        </div>
        <div style={{paddingLeft: '18px'}}>
            <div><SText fontSize={14} fontWeight={400}>Экономия по акциям</SText></div>
            <div><SText fontSize={14} fontWeight={400}>{'-656'}</SText></div>
        </div>
        <div style={{paddingLeft: '18px'}}>
            <div><SText fontSize={14} fontWeight={400}>Оплачено баллами</SText></div>
            <div><SText fontSize={14} fontWeight={400}>{'-656'}</SText></div>
        </div>
        <div style={{paddingLeft: '18px', marginBottom: '40px'}}>
            <div><SText fontSize={14} fontWeight={400}>Скидка по промокоду</SText></div>
            <div><SText fontSize={14} fontWeight={400}>{'-656'}</SText></div>
        </div>*/}
        <div>
            <div><SText fontSize={20} fontWeight={900}>Итого</SText>
            </div>

            <div><SText fontSize={20} fontWeight={900}>{total}</SText> <SText fontSize={16} fontWeight={700}
                                                                              style={{fontWeight: 900}}>{'\u00A0₽'}</SText>

            </div>
        </div>
        {isMobile && <div style={{marginTop: 24}}>
            <RoundedButton activeColor={Colors.darkBlue}
                           onPress={onMakeOrder}
                           containerStyle={{justifyContent: 'space-between', padding: '0 20px'}}>
                <SText color={'#fff'} fontWeight={550} fontSize={18}>
                    {activeMethod == 1 ? 'Оформить' : 'Оплатить'}
                </SText>
                <div><SText color={'#fff'} fontWeight={550} fontSize={18}>
                    <strong>{total}</strong>
                </SText>
                    <SText color={'#fff'} fontSize={16} fontWeight={700} style={{fontWeight: 900}}>{'\u00A0₽'}</SText>
                </div>
            </RoundedButton>
        </div>}
    </div>
}


const cards = [
    {
        id: 1,
        icon: walletModal,
        text: 'Наличными курьеру'
    }, {
        id: 3,
        icon: bankCard,
        text: 'Visa, Master Card, Maestro, МИР'
    }]


const Ordering = () => {

    window.awaitingOrder = false

    const {user, userLocation} = useContext(DataContext)
    const [triedToOrder, setTriedToOrder] = useState(false)

    const {isMobile} = useWindowDimensions(768)

    const [orderId, setOrderId] = useState(null)

    const userId = user.data?.id || 0

    const [paymentOption, setPaymentOption] = useState(1)

    const {deliverySlots, makeOrder, cart} = useContext(DataContext)
    console.log(cart)

    const [scores, setScores] = useState(Math.min(user.data?.scores, Math.floor(cart.data?.basketAmount / 2)))
    console.log({scores})

    const [selectedDay, setSelectedDay] = useState(null) //todo first

    const [errors, setErrors] = useState({name: false, address: false})

    const dayOptions = useMemo(() => {
        if (!deliverySlots.data?.slots) return []
        const _options = deliverySlots.data?.slots?.reduce((options, slot) => {
            const isToday = moment(slot.start, inputFormat).isSame(moment(), 'day')
            const isTomorrow = moment(slot.start, inputFormat).isSame(moment().add(1, 'days'), 'day')
            const label = `${isToday ? 'Сегодня, ' : (isTomorrow ? 'Завтра, ' : capitalize(moment(slot.start, inputFormat).format('dddd')) + ', ')}${moment(slot.start, inputFormat).format(dayFormat)}`

            const val = moment(slot.start, inputFormat).format(dayFormat)
            if (options.findIndex(({value}) => value === val) !== -1)
                return options

            return [
                ...options,
                {
                    label,
                    value: moment(slot.start, inputFormat).format(dayFormat)
                }
            ]
        }, [])
        setSelectedDay(_options?.[0] || {})
        return _options
    }, [deliverySlots.data?.slots])

    const timeOptions = useMemo(() => {
        if (!deliverySlots.data?.slots) return []
        return deliverySlots.data?.slots?.filter((slot) => {
            return moment(slot.start, inputFormat).isSame(moment(selectedDay?.value, dayFormat), 'day')
        })?.map(slot => ({
            value: slot,
            from: moment(slot.start, inputFormat).format(targetFormat),
            to: moment(slot.end, inputFormat).format(targetFormat)
        }))

    }, [deliverySlots.data?.slots, selectedDay, dayOptions])

    function onDaySelect(value) {
        setSelectedDay({value})
    }

    const [selectedInterval, setSelectedInterval] = useState(null)

    console.log({selectedInterval})

    useEffect(() => {
        setSelectedInterval(timeOptions?.[0]?.value)
    }, [timeOptions])

    function checkErrors() {
        if (!user.data?.full_name) {
            setErrors(prev => ({
                ...prev,
                name: true
            }))
            window.scrollTo({top: 0, behavior: 'smooth'})
            return false
        }
        if (!userLocation.data?.address) {
            setErrors(prev => ({
                ...prev,
                address: true
            }))
            window.scrollTo({top: 0, behavior: 'smooth'})
            return false
        }
        return true
    }

    function dismissErrors() {
        if (!triedToOrder) return
        setErrors({
            name: !user.data?.full_name,
            address: !userLocation.data?.address,
        })
    }

    useEffect(() => {
        dismissErrors()
    }, [user.data?.full_name, userLocation.data?.address])

    async function onMakeOrder() {

        if (!checkErrors()) return

        const response = await makeOrder({paymentType: paymentOption, deliverySlot: selectedInterval, scores})
        console.log({response})


        setTriedToOrder(true)

        try {
            let _tmr = window._tmr || (window._tmr = []);
            _tmr.push({"type": "reachGoal", "id": 3220587, "goal": "purchase"});

            window.fbq('track', 'Purchase', {value: response.master_price, currency: 'rub'});

            window.yaCounter21762586.reachGoal('submitorder')

            window.gtag('event', 'submitorder', {'event_category': 'submitorder'});
        } catch {
        }


        if (!response) {
            return alert('Произошла ошибка. Пожалуйста, попробуйте позднее')
        }
        await user.get()
        if (paymentOption === 3)
            launchPayment({
                userId: user.data.id,
                amount: response.master_price,
                orderId: response.id,
                onComplete: () => {
                    setOrderId(response.id)
                    cart.remove()
                    setTimeout(window.location.reload, 500)
                },
                onFail: () => {
                    setOrderId(response.id)
                    cart.remove()
                    setTimeout(window.location.reload, 500)
                },
                onSuccess: () => {
                    setOrderId(response.id)
                    cart.remove()
                    setTimeout(window.location.reload, 500)
                },
            })
        else {
            setOrderId(response.id)
            cart.remove()
        }
    }

    if (!orderId)
        return <div className={styles.wrapper}>
            <Helmet>
                <title>{'Оформление заказа'}</title>
                <meta name="description" content={'HalalFoods'}/>
                <meta name="keywords" content={'HalalFoods'}/>
            </Helmet>
            <Header isMobile={isMobile}/>
            <div className={styles.container}>
                <div className={styles.title}>
                    <SText fontSize={28} fontWeight={900}>{'ОФОРМЛЕНИЕ ЗАКАЗА'}</SText>
                </div>
                <div className={styles.cards}>
                    <div className={styles.infoCard}>
                        <UserInfo error={errors.name}/>
                        <Delivery selectedDay={selectedDay}
                                  dayOptions={dayOptions}
                                  error={errors.address}
                                  onDaySelect={onDaySelect}
                                  selectedInterval={selectedInterval}
                                  setSelectedInterval={setSelectedInterval}
                                  timeOptions={timeOptions}
                        />
                        <Payment onMakeOrder={onMakeOrder}
                                 cartTotal={cart.data?.basketAmount - scores + cart.data?.deliveryPrice}
                                 activeMethod={paymentOption} setMethod={setPaymentOption}/>
                    </div>
                    <div>
                        <Promo onChange={setScores}/>
                        <Price total={cart.data?.basketAmount - scores + cart.data?.deliveryPrice} scores={scores}
                               delivery={cart.data?.deliveryPrice} onMakeOrder={onMakeOrder}
                               activeMethod={paymentOption} isMobile={isMobile}/>
                    </div>
                </div>
            </div>
        </div>

    return <Redirect to={'/order/' + orderId}/>
}

export default Ordering