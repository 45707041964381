import React, {useEffect, useState} from "react";
import styles from "./Unsubscribe.module.css"
import SText from "./SText";
import greenCircle from "../assets/images/emptyCircle.png"
import greenTick from "../assets/images/greenTick.png"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import {unsubscribe} from "../api";

const reasons = [
    {reason: 'Я не люблю рассылки'},
    {reason: 'Ваши рассылки приходят слишком часто'},
    {reason: 'Неинтересная информация'},
    {reason: 'Неинтересные товары в рассылке'},
    {reason: 'Я уже не являюсь вашим клиентом'},
    {reason: 'Иная причина'}
]

const Checkbox = ({text}) => {
    const [toggle, setToggle] = useState(false)
    return <div style={{display: 'flex', alignItems: 'center', marginBottom: 15}} onClick={() => setToggle(!toggle)}>
        <div style={{marginRight: 10}}><img style={{height: 26, width: 26}} src={toggle ? greenTick : greenCircle}/>
        </div>
        <div><SText fontSize={16} fontWeight={550} lineHeight={18}>{text}</SText></div>
    </div>
}

export default () => {
    const {windowDimensions: {width}} = useWindowDimensions(600)
    const [checked, setChecked] = useState([])
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token')
    const [submited, setSubmited] = useState(false)

    const submit = async () => {
        if (checked.length) {
            await unsubscribe(token, checked)
            setSubmited(true)
        }
    }

    const breadCrumbs = [
        {
            name: 'Отписка от рассылки',
            to: '/unsubscribe'
        }
    ]
    return <div>
        <Helmet>
            <title>Отписка от рассылки</title>
            <meta name="description" content='Отписка от рассылки'/>
            <meta name="keywords" content='Отписка от рассылки'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.container}>
            <BreadCrumbs _items={breadCrumbs}/>
            {submited &&
            <div style={{marginBottom: 50}}>
                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>Мы отписали вас от нашей рассылки</SText>
                </div>

                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>Спасибо! Ваш ответ поможет нам стать лучше!</SText>
                </div></div>}
            {!submited && <div>
                <div style={{marginBottom: 15}}><SText fontSize={16} fontWeight={550} lineHeight={18}>Расскажите нам,
                    почему вы решили отписаться
                    от
                    нашей рассылки?</SText></div>
                {
                    reasons.map(value => {
                        const isChecked = checked.includes(value.reason)
                        return <div
                            onClick={() => isChecked ? setChecked(checked.filter(v => v !== value.reason)) : setChecked(prev => [...prev, value.reason])}>
                            <Checkbox text={value.reason}/></div>
                    })
                }
                <div style={{
                    width: 335,
                    background: !checked.length?'#abebc6':'#00A469',
                    borderRadius: 40,
                    height: 50,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: '40px 0'
                }} onClick={submit}><SText fontWeight={700} fontSize={18} lineHeight={18}
                                           color={'#fff'}>Отписаться</SText>
                </div>
            </div>}
        </div>
    </div>
}