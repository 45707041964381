import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./Header.module.css"
import whatsapp from '../assets/images/whatsapp.svg'
import logo from '../assets/images/logo.svg'
import logoMobile from '../assets/images/halalMobile.svg'
import SText from "./SText";
import Colors from "../utils/Colors";
import logoSmall from '../assets/images/halalFooter.svg'
import basket from '../assets/images/shoppingCartHeader.svg'
import regionHor from "../assets/images/yourRegionHorizontal.png"
import help from '../assets/images/help.svg'
import wa from '../assets/images/waWhite.svg'
import mail from '../assets/images/mailWhite.svg'
import DataContext from "../data/DataContext";
import cn from "classnames";
import TouchableOpacity from "./TouchableOpacity";
import {useRestrictions, useWindowDimensions} from "../utils/hooks";
import {NavLink, useHistory} from "react-router-dom";
import Burger from "./Burger";
import HeaderBurger from "./HeaderBurger";
import ModalContext from "../data/ModalContext";
import {SearchInput, SearchPopupDesktop} from "./Search";
import moment from "moment";
import {inputFormat} from "../config";
import {removeYear} from "../utils/other";
import {RegionContext} from "../App";

const Header = ({query = ''}) => {
    return null;
}

export default Header
